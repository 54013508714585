import {Services} from '../../../common/ts/core/services/Services';
import Company from '../../../common/ts/core/services/Company';
import CoreIntegration from '../../../common/integrations/ts/Core';
import * as Sentry from '@sentry/browser';
import {Analytics} from '../../../common/ts/core/services/Analytics';

class CarStickersCoreIntegration extends CoreIntegration {
    override setupSentry() {
        let environment = 'production';
        if (window.location.origin.indexOf('192.168') != -1 || window.location.origin.indexOf('localhost') != -1) {
            environment = 'development';
        }
        else if (window.location.origin.indexOf('staging') != -1) {
            environment = 'staging';
        }

        // Dont log with development environment
        if (environment != 'development') {
            Sentry.init({
                dsn: "https://e85f8c30a66d4e0290546d13358a6201@o116203.ingest.sentry.io/5597605",

                // To set your release version
                release: "carstickers-frontend@2.4.1",
                integrations: [Sentry.browserTracingIntegration(),],

                // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
                // Until we upgrade our account and want to use performance sampling this is being disabled
                tracesSampleRate: 0,
                environment: environment,
            });
        }
    }

    override configureServices() {
        Services.get<Company>('Company').setup(
            'Car Stickers Inc.',
            '844-647-2730',
            'sales@carstickers.com',
            '2146 NE 4th Street \n ' +
                    'Suite 100 \n ' +
                    'Bend, OR 97701',
            'https://www.carstickers.com/static/img/logo-large.png',
            'CarStickers',
            'carstickers.com',
            'https://www.facebook.com/carstickerscom/reviews/',
            'https://www.yelp.com/writeareview/biz/14GjYO0wVEoi7TjfivDnww',
            'https://www.google.com/search?q=Car+Stickers+Inc.#lrd=0x54b8c86dd7cdd411:0xacde6a97ea678204,3',
            'carstickers'
        );
        Services.get<Company>('Company').pintrist_tracking_id = '2614022552397';

        Services.get<Analytics>('Analytics').trackPage(window.location.pathname);

        super.configureServices();
    }
}
const core = new CarStickersCoreIntegration();
